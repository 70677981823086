import React from 'utils/react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal';
import { useStyletron } from 'baseui';
import CustomButton from 'components/Forms/Button';
import useTranslation from 'utils/react/useTranslation';
import Select from 'components/Forms/Select';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { api$Simulation } from 'api';

const FieldWrapper = styled.div`
  margin-top: 45px;
`;

const LastUpdateText = styled.div`
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  color: #97a0c0;
  font-style: italic;
`;

const NewSimulationWindow = ({
  onClose,
  projectId,
  serviceDate,
  commuteOfferRequestUpdate,
  setIsSimulationCreating,
}) => {
  const [templateData, setTemplateData] = React.useState();
  const { t } = useTranslation();
  const [css, theme] = useStyletron('');
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      trip: 'default',
      fleet: 'default',
    },
  });

  React.useEffect(() => {
    const fetchTemplateData = async () => {
      const data = await api$Simulation.getProjectTemplateInfo(projectId);
      setTemplateData(data);
    };
    fetchTemplateData();
  }, []);

  const onSubmit = async (formValues) => {
    const startTimeOfServiceDate = serviceDate
      ? [
          moment(serviceDate).format('YYYY-MM-DD'),
          moment.tz('00:00', 'LT', global.GEODISC_TIMEZONE).format('HH:mm:ssZ'),
        ].join('T')
      : null;

    setIsSimulationCreating(true);

    await api$Simulation.createProjectServiceSimulation(
      projectId,
      startTimeOfServiceDate
    );
    commuteOfferRequestUpdate();
  };
  return (
    <Modal
      overrides={{
        Root: {
          style: {
            zIndex: 22,
          },
        },
        Dialog: {
          style: {
            backgroundColor: theme?.colors?.containerBackground,
          },
        },
        Close: {
          style: {
            color: theme?.colors?.white,
          },
        },
      }}
      onClose={onClose}
      isOpen={true}
    >
      <ModalHeader
        className={css({
          fontFamily: `${theme?.typography?.montserrat.fontFamily} !important`,
          fontSize: '24px',
          fontWeight: 700,
          color: `${theme?.colors?.white} !important`,
          marginTop: '10px !important',
        })}
      >
        {t('c.NewSimulation.modal.title')}
      </ModalHeader>
      <ModalBody
        className={css({
          fontFamily: theme?.typography?.montserrat.fontFamily,
          color: `${theme?.colors?.white} !important`,
          fontSize: '14px',
          fontWeight: 500,
        })}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={'new-simulation-form'}>
          <FieldWrapper data-testid='Vehicle Type'>
            <Select
              label={t('c.NewSimulation.modal.tripTemplate')}
              name={'trip'}
              control={control}
              width='288px'
              options={[
                {
                  value: 'default',
                  label: t('c.MasterSettings.content.template.option.weekday'),
                },
              ]}
              disabled={!templateData}
            />
          </FieldWrapper>
          {templateData && (
            <LastUpdateText>
              <span>
                {`${t('c.NewSimulation.modal.lastUpdate')} ${moment(
                  templateData.lastUpdateTripTemplate
                ).format('DD MMM YYYY, h:mm A')}`}
              </span>
            </LastUpdateText>
          )}
          <FieldWrapper data-testid='Vehicle Type'>
            <Select
              label={t('c.NewSimulation.modal.fleetTemplate')}
              name={'fleet'}
              control={control}
              width='288px'
              options={[
                {
                  value: 'default',
                  label: t('c.MasterSettings.content.fleet.option.fullFleet'),
                },
              ]}
              disabled={!templateData}
            />
          </FieldWrapper>
          {templateData && (
            <LastUpdateText>
              <span>
                {`${t('c.NewSimulation.modal.lastUpdate')} ${moment(
                  templateData.lastUpdateFleetTemplate
                ).format('DD MMM YYYY, h:mm A')}`}
              </span>
            </LastUpdateText>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        {[
          {
            text: t('c.messages.Close'),
            fill: false,
            action: () => {
              onClose();
            },
          },
          {
            text: t('Create'),
            type: 'submit',
            formId: 'new-simulation-form',
            fill: true,
            disabled: isSubmitting,
          },
        ]?.map((data, i) => {
          return (
            <CustomButton
              key={i}
              type={data?.type}
              form={data?.formId}
              $filled={data?.fill}
              onClick={data?.action}
              disabled={data?.disabled}
            >
              {data?.text}
            </CustomButton>
          );
        })}
      </ModalFooter>
    </Modal>
  );
};

export default NewSimulationWindow;
